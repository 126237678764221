.menu-container {
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 0 75px 0 75px;
  /* margin: 0 auto;
  max-width: 1580px; */
}

.menu-title-container {
  display: flex;
}

.menu-title-container a {
  text-decoration: none;
}

.menu-list-icon {
  font-weight: bold;
}

.menu-title {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: bold;
  height: 19px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  width: 163px;
}

.progress-button-text {
  margin: 0;
  padding: 0;
}

.progress-tab {
  align-items: center;
  background: rgba(46, 46, 56, 0.1);
  color: black;
  cursor: default;
  display: inline-flex;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  padding-left: 2em;
  padding-right: 1em;
  position: relative;
  opacity: 0.5;
}

.progress-tab-clickable {
  cursor: pointer;
}

.progress-tab:not(:first-child)::before {
  border-bottom: 20px solid transparent;
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  content: '';
  left: 0;
  position: absolute;
}

.progress-tab::after {
  border-bottom: 20px solid transparent;
  border-left: 20px solid rgba(46, 46, 56, 0.1);
  border-top: 20px solid transparent;
  content: '';
  margin-right: -20px;
  position: absolute;
  right: 0;
}

.progress-tab:first-child {
  align-items: center;
  border-radius: 5px 0 0 5px;
  color: black;
  display: inline-flex;
  height: 40px;
  line-height: 40px;
  padding-left: 1em;
  padding-right: 1em;
  position: relative;
}

.progress-tab:first-child::after {
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
  content: '';
  margin-right: -20px;
  position: absolute;
  right: 0;
}

.progress-tab:last-child {
  align-items: center;
  border-radius: 0 5px 5px 0;
  color: black;
  display: inline-flex;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  padding-left: 2em;
  padding-right: 1em;
  position: relative;
}

.progress-tab:last-child::after {
  border-bottom: 20px solid transparent;
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  content: '';
  left: 0;
  position: absolute;
}

.progress-text {
  color: #2e2e38;
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.progress-check-circle {
  color: #53e08a;
  position: relative;
  right: 5px;
}

.progress-icon {
  position: relative;
  right: 5px;
}

.current-tab {
  background: #ffe600;
  opacity: 1;
}

.current-tab::after {
  border-left: 20px solid #ffe600;
}

.completed-tab {
  background: #2e2e38;
  opacity: 1;
}

.completed-tab::after {
  border-left: 20px solid #2e2e38;
}

.completed-tab .progress-text {
  color: #ffffff;
}
