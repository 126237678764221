.add-resource-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background-color: #fff;
  padding: 20px 30px;
  width: 438px;
  max-height: 100vh;
  z-index: var(--zindex-modal);
}

.add-resource-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--zindex-modal-backdrop);
}

.add-resource-modal .notification-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 10px;
  border: 2px solid rgb(255 183 129);
  border-radius: 5px;
  background-color: rgb(255 237 220);
}

.add-resource-modal .notification-container .notification-text {
  padding-left: 10px;
  color: #000000;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}
