#Inflation-adj-modal {
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  
  .Inflation-adj-modal-content {
    background-color: #fefefe;
    min-height: 197px;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
  }
  
  @media (min-height: 1025px) {
    .Inflation-adj-modal-content {
      top: 512px;
    }
  }
  
  .Inflation-adj-content {
    font-weight: bold;
    margin: 0;
    padding: 23px 25px 0 25px;
  }
  
  .Inflation-adj-modal-content p {
    font-weight: normal;
    margin: 25px 0;
    padding: 0 25px;
    text-align: center;
  }
  
  .Inflation-adj-modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 32px auto;
    width: 378px;
  }
  .test5
  {
    width: 800px;
    margin: 10px 10px 10px 100px;
    
    justify-content: center;
  }
  /* .input-container  input{
    width: 150px;  Adjust the width as needed 
    padding: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    margin: 10px 0px 0px 10px;
    box-sizing: border-box;
    text-align: left;
    margin-bottom: 1px;
    padding-left: 10px;
  justify-content: center;
  margin-inline-start: 10px;
  margin-left: 20px;
  align-items:end
  
  } */
  
  .input-container  input{
    width: 150px; /* Adjust the width as needed */
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    margin: 0px 0px 0px 120px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 1px;
  }