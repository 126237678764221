.client-view-container {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  height: 185px;
  justify-content: space-between;
  margin: 23px 0;
  width: 1290px;
}

.client-view-categories {
  bottom: 16px;
  height: 91px;
  position: relative;
  width: 260px;
}

.client-view-categories .body-text:first-child {
  border-top: 1px solid #d8d8d8;
}

.client-view-categories .body-text {
  border-bottom: 1px solid #d8d8d8;
  line-height: 15px;
  margin: 0px;
  padding: 3.7px 0;
  width: 100%;
}

.client-view-categories .body-text:last-child {
  font-weight: bold;
}

.client-view-boxes-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1023px;
}
