#delegate-modal {
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  
  .delegate-modal-content {
    background-color: #fefefe;
    min-height: 197px;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 680px;
    padding: 20px;
    border-radius: 8px ;
  }
  
  @media (min-height: 1025px) {
    .delegate-modal-content {
      top: 512px;
    }
  }
  
  /* .delegate-modal-content .delegate-modal-heading {
    font-weight: bold;
    margin: 0;
    padding: 23px 25px 0 25px;
  }
   */
  .delegate-modal-content p {
    font-weight: normal;
    margin: 10px 0;
    padding: 0 20px;
  }
/* 
  .delegate-modal-content p:first-child {
    padding-top: 5px;
  } */

  
  /* .delegate-modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 32px auto;
    width: 378px;
  }
   */
  .suggestions-list {
    max-height: 250px;
    overflow-y: scroll;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background-color:  #e7e7ea;
    width:100%;
    /* border: 1px solid #ccc; */
    z-index: 1;
  }
 .suggestions-list li{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 8px;
  cursor: pointer;
  border-bottom: 1px solid #acacac;
  height: 50px;
 }
 .suggestions-list li:last-child {
    border-bottom: none;
  }

  .suggestions-list li:hover {
    background-color: #ccc;
  }

  .user-pill {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: black;
    color: #fff;
    padding: 5px 10px;
    border-radius: 16px;
    cursor: pointer;
  }

.suggestions-list li.active {
  background-color: #ccc;
}

.input-container1{
  width: 89%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 5px;
  /* border: 1px solid #ccc; */
  margin-left: 15px;
}

.input-container1 input {
  font-size: 18px;
  border: none;
  height: 50px;
  /* padding: 5px; */
  border: 1px solid #ccc;
  width: 600px; /* Adjust the width as per your requirement */
}

.input-container1 input:focus {
  outline: none;
}

.radio-options {
  display: flex;
  align-items: center;
}

.radio-options label {
  display: flex; /* Added */
  align-items: center;
  margin-left: 26px;
}

.radio-options label:first-child {
  margin-left: 15px; /* Adjust to fit your design */
}


.delegate-modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px auto;
  width: 378px;
}

.disabled-input {
  background-color: #ededed; /* Light gray */
}
