.rates-table {
}

.rates-table .thead .tr {
  display: flex;
  background-color: #2e2e38;
  height: 40px;
  align-items: center;
}

.rates-table .thead .tr .th:nth-child(1),
.rates-table .tbody .tr .td:nth-child(1) {
  flex-basis: 33%;
}

.rates-table .thead .tr .th:nth-child(2),
.rates-table .tbody .tr .td:nth-child(2) {
  flex-basis: 33%;
}

.rates-table .thead .tr .th:nth-child(3),
.rates-table .tbody .tr .td:nth-child(3) {
  flex-basis: 20%;
}

.rates-table .thead .tr .th:not(:last-child),
.rates-table .tbody .tr .td:not(:last-child) {
  padding-right: 25px;
}

.rates-table .thead .tr .th:first-child {
  padding-left: 6px;
}

.rates-table .thead .tr .th {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.rates-table .tbody .tr {
  display: flex;
  align-items: center;
  padding: 28px 0px;
}

.rates-table .tbody .tr .td {
  font-weight: normal;
}

.rates-table .tfooter .buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
}

.rates-table .tbody .rates-upload-notificaion {
  border: 1px solid #e1e1e6;
  background-color: #f6f6fa;
  padding: 18px 16px;
  margin-bottom: 12px;
}

.rates-table .tbody hr {
  border-top: 1px solid #e7e7ea;
}

.rates-table .tbody .tr .file-name {
  color: #082c65;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-decoration: underline;
}
