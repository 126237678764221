.rates-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background-color: #fff;
  padding: 20px 30px;
  width: 438px;
  max-height: 100vh;
  overflow-y: auto;
}

.rates-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.rates-modal .description {
  color: #000000;
  font-family: EYInterstate;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 23px;
}

.rates-modal .footer-bottons-container {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.rates-select {
  width: 378px;
  height: 38px;
  margin: 20px 0px;
}

.rates-select input {
  color: inherit;
  background: 0px center;
  opacity: 1;
  width: 100%;
  grid-area: 1 / 2 / auto / auto;
  font: inherit;
  min-width: 2px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  padding: 0px;
}

.rates-dropzone {
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23979797FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.rates-dropzone.drag-active {
  opacity: 0.5;
}
