.expenses-page-container {
  background-color: #ffffff;
  min-height: 1024px;
  margin: 0 auto;
  width: 1580px;
}

@media (max-height: 1095px) {
  .expenses-body-container {
    margin: 0 0 120px 0;
    min-height: 885px;
  }
}

@media (min-height: 1096px) {
  .expenses-body-container {
    margin: 0 0 120px 0;
    min-height: calc(100vh - 260px);
  }
}

.expenses-text-container {
  color: #000000;
  margin: 0 0 0 76px;
}

.expenses-text-container .heading-two {
  margin: 0;
  padding: 31px 0 15px 0;
}

.expenses-text-container p {
  margin: 0;
}

.expenses-summary-container {
  background-color: #ffffff;
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.15);
  height: 50px;
  max-width: 1580px;
}

.expenses-summary-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 44px 0 0;
}

.expenses-summary-text p {
  padding: 0 31px 0 0;
}

.expenses-page-container .footer-container {
  margin: 0px;
}

.table-header {
  background-color: #2e2e38;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 41px;
  justify-content: space-between;
  margin: 15px auto 0 auto;
  width: 1440px;
}

.table-header-mobility {
  margin: 60px auto 0 auto;
}

.table-header p {
  font-weight: bold;
  line-height: 41px;
  margin: 0;
  padding: 0 0 0 6px;
}

.table-header span {
  height: 11px;
  margin: 8px 5px 0 0;
}

.expenses-footer {
  bottom: 0;
  height: 120px;
  position: sticky;
}

.expenses-body-container .delete-icon:hover {
  color: #747480;
}
