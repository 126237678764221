.workstream-summary {
  padding: 20px;
  background-color: #fafafc;
}

.workstream-summary header .title {
  color: #000000;
  font-family: EYInterstate;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.workstream-summary header .title-note{
  font-family: EYInterstate;
  font-size: 10px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 15px;
  padding-left: 8px;
  display: inline;
  color: #0563C1;
}

.workstream-summary .wscomment {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 10px;
}

.workstream-summary-table .tr {
  display: flex;
}

.workstream-summary-table .tr .th {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.workstream-summary-table .tr .th span {
  overflow: hidden;
  max-width: 11ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: right;
}

.workstream-summary-table .tr .td {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.workstream-summary-table .tr .td:not(:first-child) {
  text-align: right;
}

.workstream-summary-table .tr .th:first-child,
.workstream-summary-table .tr .td:first-child {
  min-width: 318px;
  max-width: 318px;
}

.workstream-summary-table .tr .th:not(:first-child),
.workstream-summary-table .tr .td:not(:first-child) {
  flex-basis: 13%;
}

.workstream-summary-table .thead .tr {
  padding: 8.5px 0;
}

.workstream-summary-table .tr {
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaf2;
}
