:root {
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-offcanvas: 1050;
  --zindex-modal: 1060;
  --zindex-popover: 1070;
  --zindex-tooltip: 1080;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: #2e2e38;
  color: #1a1a24;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'EYInterstate-Regular';
  src: local('EYInterstate-Regular'), url(./assets/fonts/EYInterstate-Regular.ttf) format('truetype');
}

.heading-one {
  font-family: EYInterstate-Regular;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}

.heading-two {
  font-family: EYInterstate-Regular;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

.heading-three {
  font-family: EYInterstate-Regular;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.body-text {
  font-family: EYInterstate-Regular;
  font-size: 14px;
  line-height: 24px;
}

.small-text {
  font-family: EYInterstate-Regular;
  font-size: 12px;
  line-height: 15px;
}

.disclaimer-text {
  font-family: EYInterstate-Regular;
  font-size: 10px;
  line-height: 15px;
  max-width: 617px;
}

.hyperlink-text {
  color: #082c65;
  font-family: EYInterstate-Regular;
  font-size: 14px;
  line-height: 15px;
}

.primary-button {
  background-color: #ffe600;
  border: none;
  border-radius: 5px;
  color: #1a1a24;
  height: 41px;
  padding: 8px 0;
  width: 174px;
}

.primary-button:hover {
  background-color: #eaeaf2;
}

.primary-button:focus {
  background-color: #ffe600;
}

.primary-button:disabled {
  opacity: 0.6;
}

.primary-button:disabled:hover {
  background-color: #ffe600;
  color: #1a1a24;
  cursor: default;
}

.secondary-button {
  background-color: #1a1a24;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  height: 41px;
  padding: 8px 0;
  width: 174px;
}

.secondary-button:hover {
  background-color: #ffe600;
  color: #1a1a24;
}

.secondary-button:focus {
  background-color: #1a1a24;
  color: #ffffff;
}

.secondary-button:disabled {
  opacity: 0.6;
}

.secondary-button:disabled:hover {
  background-color: #42424b;
  color: #ffffff;
  cursor: default;
}

.image-button {
  background-color: #ffffff;
  border: none;
}

a,
a:visited {
  color: #082c65;
}

button:hover {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.w-100 {
  width: 100%;
}

.m-0 {
  margin: 0;
}

.react-select-input {
  color: inherit;
  background: 0px center;
  opacity: 1;
  width: 100%;
  grid-area: 1 / 2 / auto / auto;
  font: inherit;
  min-width: 2px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  padding: 0px;
}
