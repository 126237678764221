.client-view-box-container {
  border: 1px solid #e1e1e6;
  cursor: pointer;
  height: 185px;
  opacity: 0.5;
  width: 244px;
}

.client-view-selected {
  border: 2px solid #1a1a24;
  opacity: 1;
}

.client-view-selected h3 {
  bottom: 1px;
  position: relative;
}

.client-view-box-container h3 {
  text-align: center;
}

.client-view-selected .client-view-box-table {
  bottom: 1px;
  position: relative;
  right: 1px;
}
.client-view-box-table {
  width: 240px;
}

.client-view-box-header {
  bottom: 12px;
  position: relative;
}

.client-view-box-header div {
  bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  position: relative;
}

.client-view-box-table td {
  bottom: 12.75px;
  font-size: 12px;
  line-height: 15.5px;
  padding: 2.9px 0;
  position: relative;
  text-align: center;
}

.client-view-box-header::after,
.client-view-box-table td::after {
  background: #e1e1e6;
  content: '';
  display: block;
  height: 1px;
  margin: auto;
  width: 97.5px;
}

.client-view-box-table .client-view-box-field-large {
  line-height: 28px;
}
