.admin-dashboard-wrapper {
  margin: auto;
  max-width: 1580px;
  background-color: #ffffff;
}

.admin-dashboard-container {
  padding: 45px 75px 45px 75px;
  min-height: calc(100vh - 140px);
}

.admin-dashboard-heading {
  margin-top: 0;
}

.admin-dashboard-container .row {
  display: -ms-flexbox;
  display: flex;
  margin-right: -25px;
  margin-left: -25px;
}

.admin-dashboard-container .col {
  -ms-flex-preferred-size: 0;
  -ms-flex-positive: 1;
  flex: 0 1 100%;
  padding-right: 25px;
  padding-left: 25px;
}

/* Footer */

.admin-dashboard-footer {
  background-color: #2e2e38;
  padding: 0 75px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
}

.admin-dashboard-footer .last-synced-text {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
  padding-right: 30px;
}
