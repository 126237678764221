.price-breakdown .title {
  color: #000000;
  font-family: EYInterstate;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.price-breakdown .percentage-bar {
  display: flex;
  height: 56px;
  margin-top: 20px;
}

.price-breakdown .percentage-bar > * {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.price-breakdown .percentage-bar .servicesFees {
  background-color: #ffb46a;
  overflow: hidden;
}

.price-breakdown .percentage-bar .subcontractors {
  background-color: #8de8ad;
  overflow: hidden;
}

.price-breakdown .percentage-bar .mobility-expense {
  background-color: #9c82d4;
  overflow: hidden;
}

.price-breakdown .percentage-bar .tech-costs {
  background-color: #87d3f2;
  overflow: hidden;
}

.price-breakdown .list .material-icons.none {
  color: #fafafc;
}

.price-breakdown .list .material-icons {
  font-size: 14px;
}

.price-breakdown .list .material-icons.servicesFees {
  color: #ffb46a;
}

.price-breakdown .list .material-icons.subcontractors {
  color: #8de8ad;
}

.price-breakdown .list .material-icons.mobility-expense {
  color: #9c82d4;
}

.price-breakdown .list .material-icons.tech-costs {
  color: #87d3f2;
}

.price-breakdown .list {
  margin-top: 32px;
}

.price-breakdown .list .list-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}

.price-breakdown .list .list-row > * {
  display: flex;
  align-items: center;
}

.price-breakdown .list .list-row .label,
.price-breakdown .list .list-row .cost {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 8px;
}

.price-breakdown .list .list-row.total-billing .label {
  all: unset;
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.86px;
  line-height: 18px;
}

.price-breakdown .list .list-row.total-billing .cost {
  all: unset;
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
}
