.form-header {
  background-color: #747480;
}

.phase-header-text {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin: 0;
  padding: 12px 0px;
}

.phases .form-header .item-row > .col:nth-child(1) .phase-header-text {
  padding: 12px 8px;
}

.phases .form-heading {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.phases .form-heading .optional-text {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 10px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 15px;
  padding-left: 8px;
}

.phases .form-heading .optional-comment {
  color: #ffe600;
  font-family: EYInterstate;
  font-size: 10px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 15px;
  padding-left: 8px;
}

.delete-icon {
  padding: 0 8px;
  cursor: pointer;
}

.add-phase-col {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  flex-basis: unset !important;
  flex-grow: unset !important;
}
.add-phase-col-disabled {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  flex-basis: unset !important;
  flex-grow: unset !important;
  opacity: 0.4;
  pointer-events: none;
}

.add-phase-text {
  display: inline-block;
  padding-left: 8px;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.phases .item-row > .col:nth-child(1) {
  flex: 0 1 33% !important;
}

.phases .item-row > .col:nth-child(2) {
  flex: 0 1 50% !important;
}

.phases .item-row > .col:nth-child(3) {
  flex: 0 1 17% !important;
}

.col-delete {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.phases .delete-icon:hover {
  color: #747480;
}
