.summary-page {
  margin: auto;
  width: 1580px;
  background-color: #ffffff;
}

.summary-page .sticky-footer {
  position: sticky;
  bottom: 0;
}

.summary-container {
  padding: 45px 75px 45px 75px;
  min-height: calc(100vh - 210px);
}

.summary-page .title-container {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}

.summary-page .title-container .title {
  margin: 0;
}

.summary-page .title-container .scenario-tag {
  margin-left: 20px;
  padding: 7px 9px;
  border-radius: 2px;
  background-color: #eaeaf2;
  color: #000000;
  font-family: EYInterstate;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 10px;
}

.summary-page .row {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 30px;
}

.summary-page .row1 {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 50px;
}

.summary-page .row1 > div:nth-child(1) {
  flex-basis: calc(35% - 25px);
}

.summary-page .row1 > div:nth-child(2) {
  flex-basis: calc(70% - 25px);
}

.summary-page .row1 > div:nth-child(2) > section {
  background-color: #fafafc;
  margin-bottom: 30px;
  padding: 16px 20px;
}

.summary-page .row1 > div:nth-child(1)> section {
  background-color: #fafafc;
  margin-bottom: 30px;
  flex-basis: 50%;
  padding: 16px 20px;
}

.summary-page .col.basic-details1 {
  flex-basis: calc(40% - 25px);
  background-color: #fafafc;
  padding: 16px 20px;
}

.summary-page .col.basic-details2 {
  flex-basis: calc(60% - 25px);
  background-color: #fafafc;
  padding: 16px 20px;
}

.summary-page .col.price-breakdown {
  flex-basis: calc(40% - 25px);
  background-color: #fafafc;
  padding: 16px 20px;
}

.summary-page .col.detailed-summary {
  flex-basis: calc(60% - 25px);
  background-color: #fafafc;
  padding: 16px 20px;
}

.summary-page .col.resources-by-rank {
  flex-basis: calc(40% - 25px);
  background-color: #fafafc;
  padding: 16px 20px;
}

.summary-page .col.leverage-breakdown {
  flex-basis: calc(60% - 25px);
  background-color: #fafafc;
  padding: 16px 20px;
}

.summary-page .col.workstream-summary {
  flex-basis: 100%;
  background-color: #fafafc;
  padding: 16px 20px;
}

.summary-page .col.workstream-summary-invisible {
  flex-basis: 100%;
  background-color: #fafafc;
  padding: 16px 20px;
  display: none;
}

.summary-page .col.phases-summary {
  flex-basis: 100%;
  background-color: #fafafc;
  padding: 16px 20px;
}

.summary-page .col.phases-summary-invisible {
  flex-basis: 100%;
  background-color: #fafafc;
  padding: 16px 20px;
  display: none;
}

.summary-page .row-expense {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 50px;
}

.summary-page .row-expense > div:nth-child(1) {
  flex-basis: calc(40% - 25px);
}

.summary-page .row-expense > div:nth-child(1) > section {
  background-color: #fafafc;
  margin-bottom: 30px;
  padding: 16px 20px;
}

.summary-page .row-expense > div:nth-child(2) {
  background-color: #fafafc;
  margin-bottom: 30px;
  flex-basis: 50%;
  padding: 16px 20px;
}
