.show-hide-check-list {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.show-hide-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 10px 110px 10px 10px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: #eaeaf2;
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.show-hide-check-list .anchor:after {
  position: absolute;
  content: "\25BC";
  right: 10px;
  font-size: 12px;
}

.show-hide-check-list.visible .anchor:after {
  transform: rotate(180deg);
}

.show-hide-check-list ul.items {
  position: absolute;
  left: 0;
  margin: 0;
  padding: 10px;
  border: 1px solid #082c65;
  border-top: none;
  width: 100%;
  display: none;
  z-index: 3;
  background-color: #f6f6fa;
  border: 1px solid #082c65;
  border-radius: 2px;
}

.show-hide-check-list ul.items li {
  list-style: none;
  padding: 6px 0;
}

.show-hide-check-list.visible .items {
  display: block;
}

.show-hide-check-list.visible .anchor {
  border: 1px solid #082c65;
}

.show-hide-check-list.visible .items .title {
  color: #000000;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
}

.desc-text {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  padding-right: 10px;
}

.show-hide-check-list .label {
  padding-left: 5px;
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}
