.icon-down:before {
  content: "\25BC";
  color: #000;
  font-size: 12px;
}

.icon-up:before {
  content: "\25B2";
  color: #000;
  font-size: 12px;
}