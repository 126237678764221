.header {
  align-items: center;
  background-color: #2e2e38;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  margin: 0 auto;
  max-width: 1580px;
}

.header-is-fixed {
  width: 1580px;
}

.header-title-container {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.header-logo {
  height: 40px;
  padding: 0 20px 0 75px;
}

.header-title {
  border-left: 1px solid #ffffff;
  color: #ffffff;
  margin: auto 0;
  padding: 0 0 0 20px;
  text-align: center;
}

.header-user-container {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  right: 70px;
  min-width: 340px;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.header-name-button {
  align-items: center;
  background: #2e2e38;
  border: none;
  border-right: solid 1px #ffffff;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: right;
  min-width: 200px;
}

.header-triangle {
  margin: 0 25px 0 10px;
  width: 8px;
}

.header-logout-button {
  align-items: center;
  background: #2e2e38;
  border: none;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  margin: 0 0 0 20px;
  text-align: right;
  width: 100px;
}

.header-logout-text {
  padding: 0 0 0 10px;
}

.header-dropdown-content {
  background-color: #2e2e38;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  display: none;
  position: absolute;
  top: 51px;
  right: 30px;
  width: 200px;
  z-index: 1;
}

.header-dropdown-content button:first-child {
  border-top: 3px solid #ffe600;
}

.header-dropdown-content button {
  background-color: #2e2e38;
  border: none;
  color: #ffffff;
  display: block;
  padding: 10px 16px;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.header-dropdown-content button:hover {
  background-color: #ffe600;
  color: #2e2e38;
  cursor: pointer;
}

a, a:hover, a:focus, a:active {
  color: inherit;
  text-decoration: none;
}