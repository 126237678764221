.notification-wrapper {
  margin: 25px 75px 0px 75px;
  padding: 11px 9px;
}

.notification-wrapper.success {
  /* opacity: 0.5; */
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: rgb(87, 225, 136, 0.5);
}

.notification-wrapper.failed {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: rgb(249, 93, 84, 0.5);
}

.notification-wrapper .notification-text {
  padding-left: 15px;
}
