.loading-container {
  background-color: #fff;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 1580px;
  height: 100vh;
}

.loading-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-inner .eyLogo {
  width: 50px;
  height: 51px;
  margin-bottom: 32px;
}
