/* Popup container */
#modal {
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  
  /* Popup content */
  .popup {
    background-color: #fefefe;
    min-height: 197px;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
  }
  
  /* Adjust top position for larger screens */
  @media (min-height: 1025px) {
    .popup {
      top: 512px;
    }
  }
  
  
  /* Paragraph style */
  .popup-content p {
    font-weight: normal;
    margin: 10px 0;
    padding: 0 25px;
    font-size: 14px;
  }
 
  /* CSS for input with the 'box' class */
.input-container  input{
  width: 150px; /* Adjust the width as needed */
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin: 0px 0px 0px 120px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 1px;
}


  
  /* Buttons container */
  .popup-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px auto;
    width: 378px;
    
  }

  .input-container{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: space-between;
  }
  
  .button-container button {
    width: 20px;
    height: 18px;
    margin: 2px auto;
    display: flex; /* Enable flex layout */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

  .error-message {
    color: red;
    font-size: 14px;
    font-weight: normal;
    padding: 0 25px;
    margin: 10px 0; /* Add margin to separate the error message from the input */
  }
  
