.Detailed-Scenario {
    padding: 20px;
    background-color: #fafafc;
  }
.Detailed-Scenario header {
    display: flex;
  }
.Detailed-Scenario header .title {
    color: #000000;
    font-family: EYInterstate;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .detailed-scenario-container table {
    border-spacing: 0;
    padding: 14px 0;
    width: 888px;
  }
  
  .detailed-scenario-container th:nth-child(1),
  .detailed-scenario-container td:nth-child(1) {
    border-bottom: 1px solid #eaeaf2;
    height: 30px;
    padding: 5px 0;
    width: 100px;
  }
  
  .detailed-scenario-container th:not(:first-child),
  .detailed-scenario-container td:not(:first-child) {
    border-bottom: 1px solid #eaeaf2;
    height: 30px;
    padding: 5px 0;
    text-align: right;
    width: 80px;
    line-height: 16px;
  }
  
  
  .detailed-scenario-container .bold-text {
    color: #000000;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
  }