.toggle-container {
  height: 40px;
  background-color: #e7e7ea;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-container .toggle-item {
  display: flex;
  align-items: center;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 8px 12px;
}

.toggle-container .toggle-item .material-icons {
  font-size: 16px;
  padding-right: 4px;
}

.toggle-container .toggle-item.muted {
  color: #747480;
}

.toggle-container .dialog-button {
  cursor: pointer;
  background-color: #2e2e38;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: unset;
  right: 4px;
  transition: all 0.3s ease;
}

.toggle-container .dialog-button.disabled {
  right: unset;
  left: 4px;
}
