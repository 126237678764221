.resource-table-container {
  position: relative;
}

.resource-table-wrapper {
  margin: 15px 0;
 /* max-height: calc(100vh - 305px);/* Screen height - (header+NavBar+Footer+ResourceSummary+padding)
  overflow: scroll;*/
}

.resource-table thead th {

  background-color: #2e2e38;
  position: sticky;
  
}

.resource-table-wrapper .close-hours {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  background-color: #2e2e38;
}

.resource-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  position: relative;
}

.resource-table thead {
  background-color: #2e2e38;
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.resource-table thead th,
.resource-table tbody td {
  padding: 12px 20px 12px 0;
}

.resource-table thead th:first-child {
  padding-left: 5px;
}

.resource-table thead th:last-child,
.resource-table tbody td:last-child {
  padding: 12px 0 12px 0;
}

.resource-table td {
  text-align: left;
}

.resource-table th {
  text-align: left;
  position: relative;
  top: 0;
  z-index: 1;
  /* vertical-align: top; */
}

.resource-table th.marketScope,
.resource-table th.workstream,
.resource-table th.role,
.resource-table th.employee {
  min-width: 195px;
}


.resource-table th.rank,
.resource-table th.hours,
.resource-table th.costRateHour,
.resource-table th.clientRateHour,
.resource-table th.clientRateDay,
.resource-table th.totalCost {
  min-width: 100px;
}

.resource-table .list-text {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.resource-table td .employee {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.resource-table td .cost-center {
  color: #000000;
  font-family: EYInterstate;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  display: inline-block;
  margin: 0;
  overflow: hidden;
  max-width: 22ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resource-table tbody tr {
  border-bottom: 1px solid #e7e7ea;
}

.resource-table tbody tr.hide-border-bottom {
  border-bottom: 1px solid transparent;
}

.resource-table .hours-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.resource-table .hours-container .edit-calendar {
  color: #082c65;
  font-size: 18px;
  margin-right: 8px;
}

.resource-table .hours-container .add_circle {
  color: #082c65;
  font-size: 18px;
  margin-right: 4px;
}

.resource-table .hours-text {
  color: #082c65;
  text-decoration: underline;
}

.resource-table thead th.hide,
.resource-table tbody td.hide {
  display: none;
}

.resource-table .hours-per-week-container {
  display: flex;
  align-items: center;
}

.resource-table .hours-per-week-container .hours-per-week-field {
  width: 82px;
}

.resource-table .hours-per-week-container .apply-container {
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}

.resource-table .hours-per-week-container .apply-icon {
  color: #082c65;
  font-size: 18px;
}

.resource-table .hours-per-week-container .apply-text {
  color: #082c65;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-decoration: underline;
}

.resource-table thead th.startDate,
.resource-table thead th.endDate {
  min-width: 190px;
}

.resource-table thead th.startDate,
.resource-table thead th.endDate {
  min-width: 190px;
}

.resource-table tbody .promotion-tag,
.resource-table tbody .gds-tag {
  padding: 7px 10px;
  margin-left: 10px;
  border-radius: 2px;
  background-color: #eaeaf2;
  color: #000000;
  font-family: EYInterstate;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 10px;
  text-align: center;
}

.resource-table tbody .actions-container {
  display: flex;
  justify-content: flex-end;
}

.resource-table .duplicate-icon {
  cursor: pointer;
  font-size: 22px;
}

.resource-table .delete-icon {
  padding: 0 4px;
  cursor: pointer;
}

.resource-table .delete-icon:hover,
.resource-table .duplicate-icon:hover {
  color: #747480;
}

.resource-table .update-cost-centre-link {
  color: #082c65;
  font-family: EYInterstate;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
}

.resource-table .update-cost-centre-link.clickable {
  text-decoration: underline;
  cursor: pointer;
}

/* Hours Table Style */

.resource-table-wrapper.hours-table {
  max-height: calc(100vh - 305px);
  /* Screen height - (header+NavBar+Footer+ResourceSummary+padding)*/
  overflow: scroll;
}

.resource-table-wrapper.hours-table::-webkit-scrollbar {
  height: 6px;
}

.resource-table.hours-table th.employee {
  max-width: 200px;
  min-width: 200px;
}

.resource-table.hours-table th.rank {
  max-width: 100px;
  min-width: 100px;
}

.resource-table.hours-table th:nth-child(1),
.resource-table.hours-table td:nth-child(1) {
  position: sticky;
  left: 0;
}

.resource-table.hours-table th:nth-child(2),
.resource-table.hours-table td:nth-child(2) {
  position: sticky;
  left: 200px;
}

.resource-table.hours-table th:nth-child(1),
.resource-table.hours-table th:nth-child(2) {
  background-color: #2e2e38;
  z-index: 1021;
}

.resource-table.hours-table td:nth-child(1),
.resource-table.hours-table td:nth-child(2) {
  background-color: white;
  z-index: 1020;
}

.resource-table.hours-table th:not(:nth-child(1), :nth-child(2)) {
  background-color: #2e2e38;
  position: sticky;
}

.resource-table.hours-table td:not(:nth-child(1), :nth-child(2)) {
  background-color: #fafafc;
}

.resource-table.hours-table th:nth-child(3),
.resource-table.hours-table td:nth-child(3) {
  padding-left: 20px;
}

.resource-table.hours-table th:last-child,
.resource-table.hours-table td:last-child {
  padding-right: 50px;
}

.resource-table.hours-table td:nth-child(3) {
  border-left: 1px solid #eaeaf2;
}

/* Cost Centre Tooltip Styles */

.tippy-tooltip.edit-hours.tippy-box {
  top: 14px;
}