.technology-expense header .title {
  color: #000000;
  font-family: EYInterstate;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.technology-expense-table {
  align-items: center;
  display: grid;
  font-family: EYInterstate;
  font-size: 14px;
  grid-template-columns: max-content auto max-content;
  grid-template-rows: 34px 34px 34px 34px;
  justify-items: end;
}

.technology-expense-item {
  border-bottom: solid 1px #eaeaf2;
  height: 100%;
  line-height: 34px;
  text-align: end;
  width: 100%;
}

.technology-expense-item.cost {
  padding: 0 10px 0 0;
  text-align: end;
}

.technology-expense-item.category {
  justify-self: start;
  text-align: start;
}

.technology-expense-item.bold {
  font-weight: bold;
}
