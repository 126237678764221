.landing-body-container {
  background-color: #ffffff;
  height: calc(100vh - 140px);
  margin: 0 auto;
  max-width: 1580px;
}

.landing-body-container-large {
  background-color: #ffffff;
  margin: 0 auto;
  max-width: 1580px;
}

.landing-text-container {
  margin: 0 auto;
  padding: 35px 0px 0 0px;
  max-width: 1290px;
}

@media (max-width: 1299px) {
  .landing-text-container {
    padding: 45px 75px 0 75px;
  }

  .landing-footer {
    padding: 0 75px;
    margin: 0;
  }
}

.landing-title {
  margin: 0;
}

.landing-intro-container {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landing-intro {
  margin: 15px 0 0 0;
  max-width: 600px;
}

.landing-download {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.landing-download a,
.landing-download a:visited {
  padding: 0 0 0 10px;
}

.landing-footer {
  align-items: center;
  background-color: #2e2e38;
  display: flex;
  height: 70px;
  justify-content: flex-end;
  margin: 0 auto;
  width: 1290px;
}

@media (max-height: 1024px) {
  .landing-body-container {
    height: 954px;
  }

  .landing-body-container-large {
    min-height: 1054px ;
    height: auto;
  }
  .landing-body-container-large::after {
    content: "";
    display: block;
    height: 100px; /* Adds the extra 100px to the height */
}

  .landing-footer {
    bottom: 0;
    left: 50%;
    margin-left: -790px;
    position: fixed;
    width: 1580px;
  }

  .landing-footer button {
    margin: 0 75px 0 0;
  }
}

@media (max-width: 1439px) {
  .landing-footer {
    width: 100vw;
  }

  .landing-footer button {
    margin: 0 50px 0 0;
  }
}

@media (max-width: 1439px) and (max-height: 1024px) {
  .landing-footer {
    margin-left: -50vw;
    width: 100vw;
  }
}

.landing-footer button:hover {
  background-color: #eaeaf2;
}

.landing-costRate-notification {
  max-width: 1290px;
  height: auto;
  border: 2px solid #ff6d00;
  border-radius: 5px;
  background-color: rgba(255, 180, 106, 0.47);
  margin: auto;
  margin-bottom: 35px;
}

.landing-costRate-notification .info-symbol {
  margin: 18px 10px 18px 16px;
  flex: 0 1 1;
}

.landing-costRate-notification .notification-text {
  margin-top: 18px;
  flex: auto;
}

.landing-costRate-notification .close-notification {
  margin: 18px 16px;
  cursor: pointer;
  flex: 1 1 1;
}
.link-style,
.link-style:visited,
.link-style:active,
.link-style:hover,
.link-style:focus {
  text-decoration: underline;
}