.global-inputs-wrapper {
  margin: auto;
  width: 1580px;
  background-color: #ffffff;
}

.global-inputs-container {
  min-height: calc(100vh - 210px);
  padding: 45px 75px;
}

.global-inputs-container .title {
  margin: 0;
  padding: 0;
}

.global-inputs-container .title-description {
  margin: 0;
  margin-top: 16px;
}

.global-inputs-container .row {
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  margin-right: -25px;
  margin-left: -25px;
}

.global-inputs-container .col {
  -ms-flex-preferred-size: 0;
  -ms-flex-positive: 1;
  flex: 0 1 100%;
  /* flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; */
  padding-right: 25px;
  padding-left: 25px;
}

.input-label {
  color: #082c65;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  display: inline-block;
  margin-bottom: 5px;
}

.input-field {
  padding: 12px 10px 12px 10px;
  border-radius: 2px;
  background-color: #eaeaf2;
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 0;
  border: 1px solid transparent;
  width: 100%;
  outline: none;
}

.input-field:disabled {
  opacity: 0.5;
}

.input-field:focus {
  border: 1px solid #082c65;
  box-shadow: inset 0 0 1px #082c65;
}

.input-field.error {
  border: 1px solid #b9251c;
  box-shadow: inset 0 0 1px #b9251c;
}

.text-error {
  color: #b9251c;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.inputs-main.disabled {
  pointer-events: none;
}

/* .input-field:invalid,
.input-field:focus:invalid {
  border: 1px solid red;
} */

.input-select {
  padding: 12px 10px 12px 10px;
  border-radius: 2px;
  background-color: #eaeaf2;
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  border: 1px solid transparent;
  width: 100%;
  outline: none;
}

.input-select:focus {
  border: 1px solid #082c65;
}

.radio-container {
  display: flex;
  padding-top: 5px;
  align-items: center;
}

.radio-container input[type="radio"] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #082c65;
  border-radius: 50%;
  outline: none;
}

.radio-container input[type="radio"]:before {
  content: "";
  display: block;
  width: 75%;
  height: 75%;
  margin: 10% auto;
  border-radius: 50%;
}

.radio-container input[type="radio"]:checked:before {
  background: #082c65;
}

.radio-container .radio-label {
  color: #000000;
  font-family: EYInterstate;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  padding-left: 10px;
}

.datepicker-wrapper {
  position: relative;
  display: inline-block;
}

.datepicker-wrapper span {
  position: absolute;
  top: 12px;
  right: 7px;
  font-size: 20px;
}

.datepicker-wrapper input::placeholder {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

/* Accordion Start */
.global-inputs-container .accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2e2e38;
  color: #ffffff;
  cursor: pointer;
  height: 40px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
}

/* .active,
.accordion:hover {
  background-color: #ccc;
} */

.global-inputs-container .accordion .panel {
  display: block;
  background-color: white;
  overflow: hidden;
}
/* Accordion End */

.global-inputs-wrapper .sticky-footer {
  position: sticky;
  bottom: 0;
}
