.deal-details-container > * + * {
  margin-top: 20px;
}

.deal-details-container .input-label.required::after {
  content: " *";
  color: #b9251c;
  font-size: 15px;
  font-weight: bold;
}
