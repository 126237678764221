.title {
  color: #000000;
  font-family: EYInterstate;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.table .tr {
  display: flex;
  justify-content: space-between;
}

.table .tr .th {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}

.table .tr .td.label {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.table .tr .td.cost {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}

.table .tr {
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaf2;
}
