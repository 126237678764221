.row {
  display: flex;
}

.row:first-child {
  padding-bottom: 4px;
}

.row div:first-child {
  flex-basis: 100px;
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 24px;
}

.row div:nth-child(2) {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
}
