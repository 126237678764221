.mobility-expenses-header {
  background-color: #747480;
  display: flex;
  flex-direction: row;
  height: 41px;
  margin: auto;
  width: 1440px;
}

.mobility-no-workstream {
  justify-content: space-between;
}

.mobility-expenses-header .body-text {
  color: #ffffff;
  font-weight: bold;
  line-height: 41px;
  margin: 0;
}

.mobility-expenses-header .large:first-child {
  padding: 0 0 0 6px;
}

.mobility-expenses-header .large {
  width: 334px;
}

.mobility-expenses-header .medium {
  width: 224px;
}

.mobility-expenses-header .small {
  width: 173px;
}

.mobility-expenses-row {
  border-bottom: 1px solid #e7e7ea;
  display: flex;
  flex-direction: row;
  height: 40px;
  margin: 22px auto;
  padding-bottom: 60px;
  width: 1440px;
}

.mobility-expenses-row .input-field {
  padding: 10px 10px;
}

.mobility-expenses-row .large {
  height: 40px;
  margin: 0 49px 0 0;
  width: 285px;
}

.mobility-expenses-row .medium {
  height: 40px;
  margin: 0 50px 0 0;
  width: 174px;
}

.mobility-expenses-row .mobility-no-workstream-medium {
  margin: 0 50px 0 80px;
}

.mobility-expenses-row .small {
  height: 40px;
  margin: 0 50px 0 0;
  width: 71px;
}

.mobility-expenses-row .mobility-no-workstream-small {
  left: 30px;
  position: relative;
}

.mobility-expenses-row .delete-icon {
  height: 20px;
  margin: 11px 0 0 0;
}

.mobility-expenses-row button {
  background-color: #ffffff;
  border: none;
}

.add-mobility-expense-button {
  background-color: #ffffff;
  border: none;
  color: #082c65;
  display: flex;
  flex-direction: row;
  margin: 0 0 0 75px;
  padding: 0 0 20px 0;
}

.add-mobility-expense-button p {
  margin: 0;
  padding: 0 0 0 5px;
}

.mobility-expenses-row .dropdown {
  width: 172px;
}

.field-error-container {
  display: flex;
  flex-direction: column;
}

.add-mobexpenses-text {
  display: inline-block;
  padding-left: 8px;
  padding-top: 5px !important;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.add-mobmargin {
  margin-top: 10px;
}
