.detailed-summary {
  padding: 20px;
  background-color: #fafafc;
}

.detailed-summary header {
  display: flex;
}

.detailed-summary header .title {
  color: #000000;
  font-family: EYInterstate;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.detailed-summary header .contingency {
  margin-left: 10px;
  padding: 7px 9px;
  border-radius: 2px;
  background-color: #eaeaf2;
  color: #000000;
  font-family: EYInterstate;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 10px;
  text-align: center;
}

.detailed-summary-table .tr {
  display: flex;
}

.detailed-summary-table .tr .th {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}

.detailed-summary-table .tr .th:first-child,
.detailed-summary-table .tr .td:first-child {
  flex-basis: 50%;
}

.detailed-summary-table .tr .th:not(:first-child),
.detailed-summary-table .tr .td:not(:first-child) {
  flex-basis: 25%;
}

.detailed-summary-table .tr .td.label {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.detailed-summary-table .tr .td.including,
.detailed-summary-table .tr .td.excluding {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}

.detailed-summary-table .thead .tr {
  padding: 8.5px 0;
}

.detailed-summary-table .tr {
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaf2;
}
