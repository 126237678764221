#privacy-modal {
  background-color: #e7e7ea;
  display: none;
  min-height: 100vh;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  top: 0px;
  width: 1580px;
  z-index: 1021;
}

@media (max-height: 899px) {
  #privacy-modal {
    position: absolute;
  }
}

@media (min-height: 900px) {
  #privacy-modal .privacy-modal-accepted {
    height: 100vh;
    position: fixed;
    top: 0;
  }
}

.privacy-modal-accepted {
  height: 1024px;
}

.privacy-modal-not-accepted {
  margin: 70px 0 0 0;
  height: 1024px;
}

.privacy-modal-content {
  background-color: #fefefe;
  height: calc(100vh - 240px);/* - (footer+header+50px gap top and bottom)*/
  margin: 120px auto;
  width: 845px;
}

.privacy-modal-accepted .privacy-modal-content {
  background-color: #fefefe;
  height: calc(100vh - 240px);/* - (footer+header+50px gap top and bottom)*/
  margin: 120px auto;
  width: 845px;
}

.privacy-modal-content .privacy-modal-heading {
  font-weight: bold;
  margin: 0 0 16px 0;
  padding: 29px 0 0 25px;
}

.privacy-modal-text-container {
  height: calc(100vh - 373px);
  padding: 0 0 0 25px;
  overflow: scroll;
  width: 820px;
}

.privacy-modal-content p {
  font-weight: normal;
  margin: 0;
  padding: 0 10px 19px 0;
}

.privacy-modal-content p:last-child {
  font-weight: normal;
  margin: 0;
  padding: 0 10px 0 0;
}

.privacy-modal-table{
  border: 1px solid black;
  border-collapse: collapse;
}

.privacy-modal-table-highlight{
  background-color:orange;
}

.privacy-modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
  padding: 10px 0 0 0;
  width: 794px;
}

.privacy-arrow {
  height: 15.5px;
  position: relative;
  left: 50px;
  top: 2.5px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 4px;
}
