.eyview-box-container {
  border: 1px solid #e1e1e6;
  cursor: pointer;
  height: 375px;
  opacity: 0.5;
  width: 244px;
}

.eyview-selected {
  border: 2px solid #1a1a24;
  opacity: 1;
}

.eyview-selected h3 {
  bottom: 1px;
  position: relative;
}

.eyview-box-container h3 {
  text-align: center;
}

.eyview-selected .eyview-box-table {
  bottom: 1px;
  position: relative;
  right: 1px;
}
.eyview-box-table {
  width: 240px;
}

.eyview-box-header {
  bottom: 12px;
  font-size: 12px;
  font-weight: normal;
  height: 30px;
  line-height: 15px;
  position: relative;
  width: 97.5px;
}

.eyview-box-table td {
  bottom: 12.5px;
  font-size: 12px;
  line-height: 15px;
  padding: 3px 0;
  position: relative;
  text-align: center;
}

.eyview-box-header::after,
.eyview-box-table td::after {
  background: #e1e1e6;
  bottom: -0.7px;
  content: '';
  display: block;
  height: 1px;
  margin: auto;
  position: relative;
  width: 97.5px;
}

.eyview-box-large-cell::after {
  bottom: -7px !important;
}

.eyview-box-table .eyview-box-field-large {
  line-height: 28px;
}

.eyview-box-table .eyview-box-small-cell {
  bottom: 14px;
}

.eyview-box-large-cell {
  height: 35px;
}
