.title {
  color: #000000;
  font-family: EYInterstate;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header div:nth-child(2) {
  display: flex;
}

.header div:nth-child(2) > div {
  display: flex;
  align-items: center;
}

.header div:nth-child(2) > div + div {
  padding-left: 20px;
}

.header div:nth-child(2) > div {
  display: flex;
  align-items: center;
}

.header div:nth-child(2) > div div {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 6px;
}

.nonGDSCircleIcon {
  font-size: 16px;
  color: #ffb46a;
}

.GDSCircleIcon {
  font-size: 16px;
  color: #87d3f2;
}

.leverage-breakdown-table .tr {
  display: flex;
}

.leverage-breakdown-table .tr .th {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.leverage-breakdown-table .tr .th.label,
.leverage-breakdown-table .tr .td.label {
  width: 200px;
}

.leverage-breakdown-table .tr .th.breakdown,
.leverage-breakdown-table .tr .td.breakdown {
  flex-basis: calc(100% - 275px);
}

.leverage-breakdown-table .tr .th.allocation,
.leverage-breakdown-table .tr .td.allocation {
  width: 135px;
  text-align: right;
}

.leverage-breakdown-table .tr .td.allocation {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.leverage-breakdown-table .tr .td.label {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.leverage-breakdown-table .thead .tr {
  padding: 8.5px 0;
}

.leverage-breakdown-table .tr {
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaf2;
}

.breakdownbar {
  /* background-color: red; */
  width: 100%;
  height: 16px;
  text-align: center;
}

.breakdownbar .nonGDSBar {
  height: 100%;
  background-color: #ffb46a;
  display: inline-block;
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
}

.breakdownbar .GDSBar {
  height: 100%;
  background-color: #87d3f2;
  display: inline-block;
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
}

.breakdownbar .nonGDSBar span {
  padding-left: 10px;
}

.breakdownbar .GDSBar span {
  padding-right: 10px;
}
