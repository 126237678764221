.footer-container {
  align-items: center;
  background-color: #2e2e38;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 0 75px 0 75px;
  width: 1580px;
}

.back-button {
  background-color: #42424b;
}

.col-footer {
  display: flex;
  align-items: center;
}

.col-footer:first-child {
  flex: 0 1 40%;
}

.worksheet-name-container {
  display: flex;
  flex-basis: 100%;
  margin-left: 30px;
}

.edit-worksheet-name-field {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-style: italic;
  outline: none;
  border: 0;
  padding: 12px 10px 12px 10px;
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.edit-worksheet-name-field:invalid {
  border: 1.5px solid #f95d54;
}

.edit-worksheet-name-field:invalid:focus {
  border: 1.5px solid #f95d54;
}

.worksheet-name-text {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.next-button {
  display: inline-flex;
  align-items: center;
}

.next-button-text {
  flex-grow: 1;
  padding: 0 0 0 25px;
}

.next-button-text-summary {
  flex-grow: 1;
}

.next-icon {
  padding-right: 10px;
}

.col-footer:nth-child(2) > :not(:first-child) {
  margin-left: 30px;
}

.last-saved-text {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
}

.worksheet-name-edit-icon {
  font-size: 16px;
  margin-left: 10px;
  color: #ffffff;
  cursor: pointer;
}
