.workstreams .item-row > .col:nth-child(1) {
  flex: 0 1 33% !important;
}

.workstreams .item-row > .col:nth-child(2) {
  flex: 0 1 50% !important;
}

.workstreams .item-row > .col:nth-child(3) {
  flex: 0 1 17% !important;
}

.workstreams .delete-icon:hover {
  color: #747480;
}

.workstream-header-text {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin: 0;
  padding: 12px 0px;
}

.workstreams .form-header .item-row > .col:nth-child(1) .workstream-header-text {
  padding: 12px 8px;
}

.workstreams .form-heading {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.workstreams .form-heading .optional-text {
  color: #ffffff;
  font-family: EYInterstate;
  font-size: 10px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 15px;
  padding-left: 8px;
}

.workstreams .form-heading .optional-comment {
  color: #ffe600;
  font-family: EYInterstate;
  font-size: 10px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 15px;
  padding-left: 8px;
}
