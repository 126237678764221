.wrapper {
  display: flex;
}

.wrapper > div {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}

.label {
  color: #000000;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 24px;
  padding-left: 6px;
}

.value {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 4px;
}

.icon {
  font-size: 22px;
}
