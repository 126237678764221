.costCenterModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background-color: #fff;
  padding: 20px 30px;
  width: 438px;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 1055;
}

.costCenterModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--zindex-modal);
}

.description {
  color: #000000;
  font-family: EYInterstate;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 16px;
}

.inputFieldWrapper {
  position: relative;
}

.searchIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.resultsCountText {
  color: #747480;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
}

.resultsContainer {
  margin-top: 10px;
  height: 279px;
  overflow-x: scroll;
}

.resultItem {
  background-color: #fafafc;
  padding: 10px 20px;
  border-bottom: 1px solid #e7e7ea;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.costCentreText {
  opacity: 0.6;
  color: #000000;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  padding: 5px 0;
}

.selected {
  background-color: #e7e7ea;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
