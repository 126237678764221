.resources-container-wrapper {
  margin: 0 auto;
  width: 1580px;
  background-color: #fff;
}

.resources-container {
  padding: 45px 75px 45px 75px;
  min-height: calc(100vh - 210px);
}

.resources-container .title {
  margin: 0;
  padding: 0;
}

.resources-container .title-description {
  margin: 0;
  margin-top: 16px;
  max-width: 50%;
}

.resources-summary-container {
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.15);
  height: 50px;
  max-width: 1580px;
  display: flex;
  justify-content: flex-end;
  padding: 0 75px;
}

.resources-summary-container p:not(:last-child) {
  padding-right: 30px;
}

.resource-sticky-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: var(--zindex-sticky);
}
