.scenarios-container {
  min-height: 1061px;
  margin: auto;
  width: 1580px;
}

.scenarios-main-container {
  background-color: #ffffff;
  min-height: 921px;
  margin: auto;
  width: 1580px;
}

.scenarios-content-container {
  min-height: 921px;
  margin: 0 auto 0 auto;
  width: 1290px;
}

.scenarios-content-container h2 {
  margin: 40px 0 0 0;
}

.scenarios-description {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1290px;
}

.scenarios-description p {
  width: 680px;
}

.scenarios-contingency-container {
  align-items: center;
  display: flex;
  width: 400px;
}
.scenarios-contingency-container1 {
  align-items: center;
  display: flex;
  width: 330px;
  margin-left: 70px;
}
.scenarios-contingency-sections {
  display: block;
  margin-top: -48px
}
.scenarios-contingency-container .body-text {
  margin-right: 15px;
  max-width: 150px;
}

.scenarios-contingency-dropdown {
  position: relative;
  display: inline-block;
}
.scenarios-surcharge-dropdown {
  position: relative;
  display: inline-block;
}

.scenarios-contingency-button {
  align-items: center;
  background-color: #eaeaf2;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 38px;
  margin-right: 20px;
  width: 71px;
}
.scenarios-surcharge-button {
  align-items: center;
  background-color: #eaeaf2;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 38px;
  margin-right: 20px;
  width: 71px;
}

.scenarios-contingency-button .body-text {
  margin: 0;
  width: 50px;
}
.scenarios-surcharge-button .body-text {
  margin: 0;
  width: 50px;
}

.scenarios-contingency-button-arrow {
  font-size: 20px;
}
.scenarios-surcharge-button-arrow {
  font-size: 20px;
}

.scenarios-contingency-dropdown-content {
  background-color: #eaeaf2;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  display: none;
  width: 70px;
  position: absolute;
  z-index: 1;
}
.scenarios-surcharge-dropdown-content {
  background-color: #eaeaf2;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  display: none;
  width: 70px;
  position: absolute;
  z-index: 1;
}

.scenarios-contingency-dropdown-content button {
  background-color: #eaeaf2;
  border: none;
  color: black;
  display: block;
  padding: 5px 17px;
  text-align: left;
  text-decoration: none;
  width: 71px;
}
.scenarios-surcharge-dropdown-content button {
  background-color: #eaeaf2;
  border: none;
  color: black;
  display: block;
  padding: 5px 17px;
  text-align: left;
  text-decoration: none;
  width: 71px;
}
.scenarios-contingency-dropdown-content button:hover {
  background-color: #ffe600;
  color: #2e2e38;
  cursor: pointer;
}
.scenarios-surcharge-dropdown-content button:hover {
  background-color: #ffe600;
  color: #2e2e38;
  cursor: pointer;
}

.scenarios-contingency-apply-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.scenarios-contingency-apply-container .hyperlink-text {
  margin-left: 10px;
  text-decoration: underline;
  width: 75px;
}

.scenarios-footer {
  bottom: 0;
  height: 70px;
  position: sticky;
}

.detailed-header p {
  right: 470px;
  position: relative;
}

.detailed-header .detailed-header-name-box {
  background-color: #eaeaf2;
  border-radius: 3px;
  color: #000000;
  font-size: 10px;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  margin-left: 10px;
  text-align: center;
  width: 105px;
}

.scenarios-white-space {
  height: 20px;
}

.table-header1 {
  background-color: #2e2e38;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 41px;
  justify-content: space-between;
  margin: 15px auto 0 auto;
  width: 1290px;
}


.table-header1 p {
  font-weight: bold;
  line-height: 41px;
  margin: 0;
  padding: 0 0 0 6px;
}

.table-header1 span {
  height: 11px;
  margin: 8px 5px 0 0;
}
.technology-cost-modal-buttons {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 2px;
  width: 478px;
}

.cola-button {
  background-color: #ffe600;
  border: none;
  border-radius: 5px;
  color: #1a1a24;
  height: 41px;
  padding: 8px 0;
  width: 274px;
}

.cola-button:hover {
  background-color: #eaeaf2;
}

.cola-button:focus {
  background-color: #ffe600;
}

.cola-button:disabled {
  opacity: 0.6;
}

.cola-button:disabled:hover {
  background-color: #ffe600;
  color: #1a1a24;
  cursor: default;
}