.top-buttons-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.top-buttons-container .left-section {
  display: flex;
}

.top-buttons-container .left-section > * + * {
  margin-left: 30px;
}
