.title {
  color: #000000;
  font-family: EYInterstate;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.resources-by-rank-table .tr {
  display: flex;
}

.resources-by-rank-table .tr .th {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}

.resources-by-rank-table .tr .th:first-child,
.resources-by-rank-table .tr .td:first-child {
  width: 200px;
}

.resources-by-rank-table .tr .th:not(:first-child),
.resources-by-rank-table .tr .td:not(:first-child) {
  flex-basis: calc((100% - 200px) / 2);
}

.resources-by-rank-table .tr .th:nth-child(2),
.resources-by-rank-table .tr .td:nth-child(2) {
  flex-basis: calc((100% - 220px) / 2);
}

.resources-by-rank-table .tr .th:nth-child(3),
.resources-by-rank-table .tr .td:nth-child(3) {
  flex-basis: calc((100% - 120px) / 2);
}
 
.resources-by-rank-table .tr .td.label {
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.resources-by-rank-table .tr .td.hours,
.resources-by-rank-table .tr .td.cost {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}

.resources-by-rank-table .thead .tr {
  padding: 8.5px 0;
}

.resources-by-rank-table .tr {
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaf2;
}
