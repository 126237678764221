#import-modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.import-modal-content {
  background-color: #fefefe;
  height: 387px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
}

.import-modal-content .import-modal-heading {
  font-weight: bold;
  margin: 0;
  padding: 30px 36px 0 36px;
}

.import-modal-content p {
  font-weight: normal;
  margin: 10px 0;
  padding: 0 36px;
}

.import-modal-content .body-text {
  margin: 0;
  position: relative;
  top: 27px;
}

.drop-zone {
  height: 160px;
  margin: 1px auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 378px;
}

.drop-zone:before {
  border: 4px dashed #747480;
  bottom: -3px;
  content: "";
  left: -3px;
  position: absolute;
  right: -3px;
  top: -3px;
}

#import-input {
  bottom: 160px;
  cursor: copy;
  height: 158px;
  left: 35px;
  opacity: 0;
  position: relative;
  width: 376px;
}

.drop-zone-image {
  margin: 45px;
  width: 43px;
}
.drop-zone .drop-zone-text {
  margin: 0 53px;
}

.drop-zone .drop-zone-replace-text {
  padding: 20px;
}

.drop-zone .drop-zone-failed-text {
  color: #e0362c;
}

.drop-zone .drop-zone-filename-text {
  font-weight: bold;
  padding: 0 10px;
}

.import-modal-buttons {
  bottom: 160px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 32px auto;
  position: relative;
  width: 378px;
}
