.base-container {
  margin: 25px 0 0 0;
}
.base-body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1290px;
}

.base-categories {
  margin: 40px 0 0 0;
  width: 250px;
}

.base-categories p {
  border-bottom: solid 1px #d8d8d8;
  bottom: 2px;
  margin: 3.75px 0;
  position: relative;
}

.base-categories p:first-child {
  font-weight: bold;
  padding: 0 0 30px 0;
}

.base-legend {
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: space-between;
  width: 380px;
}

.base-red-circle {
  background-color: #57e188;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  left: 5px;
  position: relative;
  top: 22px;
  width: 8px;
}

.base-green-circle {
  background-color: #f95d54;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  left: 5px;
  position: relative;
  top: 22px;
  width: 8px;
}
