.editable-box-container {
  border: solid 1px #c4c4cd;
  cursor: pointer;
  height: 337px;
  opacity: 0.5;
  width: 244px;
}

.editable-box-container h3,
.editable-box-container-selected h3 {
  margin: 8px 10px;
  text-align: center;
}

.editable-box-container-selected {
  border: solid 2px #2e2e38;
  bottom: 22px;
  position: relative;
  opacity: 1;
  width: 244px;
}

.editable-box-selected-heading h3 {
  padding: 1px;
}

.editable-box-selected-heading .editable-box-selected-text {
  background-color: #2e2e38;
  color: #ffffff;
  font-weight: bold;
  right: 2px;
  margin: 0;
  position: relative;
  text-align: center;
  width: 244px;
}

.editable-box-container-selected .editable-box-content {
  left: 1px;
  position: relative;
}

.editable-box-container .editable-box-content {
  position: absolute;
  top: 333px;
  width: 244px;
}

.editable-box-container-selected .editable-box-cost-container {
  bottom: 9px;
  display: flex;
  flex-direction: row;
  height: 30px;
  left: 40px;
  margin-bottom: 2px;
  position: relative;
}

.editable-box-cost {
  font-weight: normal;
}

.editable-box-cost-symbol {
  font-weight: normal;
  position: relative;
  right: 25px;
}

.editable-box-container-selected .editable-box-cost-input {
  font-size: 16px;
  position: relative;
  right: 12px;
  top: 8px;
}

.editable-box-container table,
.editable-box-container-selected table {
  bottom: 10px;
  margin: auto;
  position: relative;
  width: 240px;
}

.editable-box-container th,
.editable-box-container-selected th {
  font-size: 12px;
  font-weight: normal;
  height: 29px;
  position: relative;
  right: 5px;
  width: 97px;
}

.editable-box-container th::after,
.editable-box-container-selected th::after {
  background: #d8d8d8;
  bottom: 0px;
  content: '';
  height: 1px;
  left: 8px;
  position: absolute;
  width: 85%;
}

.editable-box-container td,
.editable-box-container-selected td {
  width: 97px;
}

.editable-box-container input {
  border: none;
  bottom: 2px;
  height: 19px;
  margin: 2.6px 8px;
  position: relative;
  right: 6px;
  text-align: center;
  width: 95px;
}

.editable-box-container-selected input {
  background-color: #eaeaf2;
  border: none;
  border-radius: 2px;
  bottom: 1.5px;
  height: 18px;
  margin: 2.6px 8px 3.6px 8px;
  position: relative;
  text-align: center;
  width: 83px;
}

.editable-box-input-container {
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  left: 2px;
  position: relative;
  width: 100px;
}

.editable-box-input-container .body-text {
  line-height: 20.5px;
  margin: 0;
  position: relative;
  right: 52px;
}

.editable-box-container-selected .editable-box-input-container .body-text {
  bottom: 0px;
  line-height: 20.5px;
  margin: 0;
  position: relative;
  right: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editable-box-container-selected .editable-box-readonly {
  background-color: #ffffff;
}

.large-box {
  height: 47px !important;
}

.editable-box-container-selected .editable-box-error {
  border: solid 1px #f95d54;
}

.editable-box-input-error p {
  display: none;
}

.editable-box-container-selected .editable-box-input-error p {
  color: #f95d54;
  display: block;
  font-size: 12px;
  height: 10px;
  position: relative;
  right: 60px;
  top: 7px;
  width: 100px;
  z-index: 1;
}

.editable-box-container-selected .editable-box-cost-error p {
  position: relative;
  right: 155px;
  top: 11px;
}
