.landing-table-container {
  margin: 20px 0 0 0;
  overflow-x: auto;
}

.landing-table {
  border-spacing: 0px;
  table-layout: auto;
  width: 100%;
}

.landing-table tr {
  height: 60px;
}

.landing-table .landing-table-heading {
  background-color: #2e2e38;
  height: 50px;
}

th.worksheet-name,
th.engagement-name,
th.client {
  width: 20%;
}

th.date {
  width: 18%;
}

th.created {
  width: 10%;
}

th.delete {
  width: 24%;
}
.landing-table-heading th:first-child {
  padding: 0 0 0 12px;
}

.landing-table-heading th {
  color: #ffffff;
  text-align: left;
}

.landing-table-heading th:hover {
  cursor: pointer;
}

.landing-table-heading th:last-child {
  text-align: center;
}

.landing-table-heading th:last-child:hover {
  border-bottom: none;
  cursor: auto;
}

.landing-table td:first-child {
  color: #082c65;
  cursor: pointer;
  font-family: EYInterstate-Regular;
  text-decoration: underline;
  font-size: 14px;
  line-height: 15px;
  padding: 0 70px 0 12px;
}

.landing-table td {
  border-bottom: solid 1px #e7e7ea;
  cursor: default;
}

.landing-table td:last-child {
  text-align: right;
}

.landing-table-triangle {
  margin: 0 0 0 10px;
  padding: 0 0 2px 0;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 1290px;
}

.pagination-container p {
  margin: 15px 40px 0 0;
}

.pagination-container button {
  padding: 20px;
  width: 13px;
}

.pagination-container .pagination-prev-button {
  margin: 0 15px 0 -20px;
}

.landing-table .delete-button:hover {
  color: #747480;
}
.landing-table .duplicate-icon {
  cursor: pointer;
  font-size: 22px;
}

.landing-table .duplicate-icon:hover {
  color: #747480;
}

.landing-table body-text tbody .actions-container {
  display: flex;
  justify-content: flex-end;
}
