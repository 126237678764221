.eyview-container {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  height: 401px;
  justify-content: space-between;
  margin: 23px 0;
  width: 1290px;
}

.eyview-categories {
  bottom: 13px;
  height: 314px;
  position: relative;
  width: 260px;
}

.eyview-categories .body-text:first-child {
  border-top: 1px solid #d8d8d8;
  margin-top: 30px;
}

.eyview-categories .body-text {
  border-bottom: 1px solid #d8d8d8;
  line-height: 15px;
  margin: 0px;
  padding: 3.7px 0;
  width: 100%;
}

.eyview-boxes-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1023px;
}
