.loading-changes-container {
  background-color: #fff;
  height: calc(100vh - 140px);
  display: grid;
  place-items: center;
}

.loading-changes-container .loading-changes-inner {
  display: grid;
  place-items: center;
}

.loading-changes-container .loading-changes-inner > p {
  padding-bottom: 30px;
  font-weight: bold;
}
