.named-resource-container {
  margin: 20px 0px 0px;
}

.named-resource-input-field-wrapper {
  position: relative;
}

.named-resource-input-field-wrapper span {
  position: absolute;
  top: 10px;
  right: 10px;
}

.named-resource-container .results-count-text {
  color: #747480;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
}

.named-resource-container .results-container {
  margin-top: 10px;
  height: 312px;
  overflow-x: scroll;
}

.named-resource-container .results-container[attr-issearching='true'] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.named-resource-container .result-item {
  background-color: #fafafc;
  padding: 10px 20px;
  border-bottom: 1px solid #e7e7ea;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 400px;
}

.named-resource-container .result-item .name {
  color: #000000;
  font-family: EYInterstate;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  flex-basis: 60%;
  width:40%;
}

.named-resource-container .result-item .cost-centre {
  opacity: 0.6;
  color: #000000;
  font-family: EYInterstate;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  padding-left: 14px;
  width:60%;
}

.named-resource-container .result-item.selected {
  background-color: #e7e7ea;
}

.named-resource-container .footer-bottons-container {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
