.detailed-resources-container table {
  border-spacing: 0;
  padding: 14px 0;
  width: 1290px;
}

.detailed-resources-container th:nth-child(1),
.detailed-resources-container td:nth-child(1) {
  border-bottom: 1px solid #e7e7ea;
  height: 30px;
  padding: 0;
  width: 100px;
}

.detailed-resources-container th:not(:first-child),
.detailed-resources-container td:not(:first-child) {
  border-bottom: 1px solid #e7e7ea;
  height: 30px;
  padding: 0;
  text-align: center;
  width: 80px;
}

.detailed-resources-container .bold-text {
  font-weight: bold;
}
