#delete-modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.delete-modal-content {
  background-color: #fefefe;
  min-height: 197px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 430px;
}

@media (min-height: 1025px) {
  .delete-modal-content {
    top: 512px;
  }
}

.delete-modal-content .delete-modal-heading {
  font-weight: bold;
  margin: 0;
  padding: 23px 25px 0 25px;
}

.delete-modal-content p {
  font-weight: normal;
  margin: 10px 0;
  padding: 0 25px;
}

.delete-modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 32px auto;
  width: 378px;
}
